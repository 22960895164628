import React from 'react';
import { LinkBlock } from '@latitude/link-block';
import './_dropdown.scss';
import '../Chevron/_chevron.scss';

const DropdownStyled = props => (
  <React.Fragment>
    <div className="dropdown-container">
      <LinkBlock
        className="bg-brand-bright"
        onClick={props.handleToggleVisibility}
        role="presentation"
        fgColor="white"
        chevronDirection={props.visibility ? 'up' : 'down'}
        chevronColour="white"
        noBorder={false}
      >
        {/* Display initial value if not menu item has been selected */}
        {!props.currentSelectedOption && props.categoryOptions[0]}
        {props.currentSelectedOption}
      </LinkBlock>
      {/* Show/Hide menu */}
      {props.visibility && (
        <ul
          className="dropdown__list"
          onClick={props.handleToggleVisibility}
          role="presentation"
        >
          {props.categoryOptions.map((categoryOption, index) => (
            <li
              className="dropdown__list-item"
              key={index}
              onClick={() => props.handleClick(categoryOption)}
              role="presentation"
            >
              {categoryOption}
            </li>
          ))}
        </ul>
      )}
    </div>
  </React.Fragment>
);

export default DropdownStyled;
