import React from 'react';
import classnames from 'classnames';
import { Chevron } from '@latitude/chevron';
import './_dropdown.scss';
import { COLOR } from '@/utils/constants';

const DropdownFilter = props => {
  const className = classnames('dropdown', props.className);

  return (
    <div
      className={`${
        props.flexibleWidth ? 'dropdown-container' : 'dropdown-container-fixed'
      }`}
      style={{ position: 'relative' }}
    >
      <select
        className={className}
        value={props.selectedItem}
        onChange={() => props.handleChange(event)}
      >
        {props.categoryOptions.map((option, index) => (
          <option key={index} value={option}>
            {option}
          </option>
        ))}
      </select>
      <Chevron
        direction="down"
        color={COLOR.WHITE}
        css={`
          position: absolute;
          top: calc(50% - 4px);
          right: 15px;
          transform: rotate(180deg) translateY(-50%);
          pointer-events: none;
        `}
      />
    </div>
  );
};

export default DropdownFilter;
