import React, { Component } from 'react';
import { scroller } from 'react-scroll';
import DropdownFilter from '../Dropdown/DropdownFilter';
import DropdownStyled from '../Dropdown/DropdownStyled';
import CardMedia from '../CardMedia/CardMedia';
import BrandedCard from '../CardMedia/BrandedCard';
import { BREAKPOINT } from '../../utils/constants';

class FilterList extends Component {
  state = {
    selectedItem: '',
    visibility: false
  };

  componentDidMount = () => {
    this.handleFiltering(this.handleUrlHash());
  };

  handleUrlHash = () => {
    const hash = window.location.hash.substring(1);

    if (hash.toLowerCase() === 'digital-wallets') {
      scroller.scrollTo('digital-wallets', {
        duration: 500,
        smooth: true,
        offset: -80
      });
      return;
    }

    const hashCapitalized =
      `${hash}`.charAt(0).toUpperCase() + `${hash}`.slice(1).replace(/-/g, ' ');
    return hashCapitalized;
  };

  handleFiltering = value => {
    if (value.includes('?')) {
      value = value.replace(/\?.*/, '');
    }
    this.setState({
      selectedItem: value
    });
  };

  handleClick = categoryOption => {
    this.handleFiltering(categoryOption);
  };

  handleChange = event => {
    const card = event.target.value;
    this.handleFiltering(card);
  };

  handleToggleVisibility = () => {
    this.setState(prevState => ({
      visibility: !prevState.visibility
    }));
  };

  render() {
    return (
      <div className={this.props.className}>
        {/* Native browser dropdown list shows on small devices */}
        <div className="d-xl-none">
          <DropdownFilter
            categoryOptions={this.props.selectedItem}
            handleFiltering={this.handleFiltering}
            handleChange={this.handleChange}
          />
        </div>
        {/* Custom styled dropdown list to be displayed on larger devices */}
        <div className="d-none d-xl-block">
          <DropdownStyled
            visibility={this.state.visibility}
            handleToggleVisibility={this.handleToggleVisibility}
            currentSelectedOption={this.state.selectedItem}
            categoryOptions={this.props.selectedItem}
            handleFiltering={this.handleFiltering}
            handleClick={this.handleClick}
          />
        </div>
        {!this.props.isBranded ? (
          <>
            <CardMedia
              hasMininumHeight
              cardMediaBackground
              data={
                this.state.selectedItem
                  ? this.props.data.filter(
                      item =>
                        item.category.indexOf(this.state.selectedItem) > -1
                    )
                  : this.props.data
              }
            />
          </>
        ) : (
          <div
            css={`
              max-width: 920px;
              margin: 0 auto;
              padding-top: 32px;
              @media (min-width: ${BREAKPOINT.LG}) {
                padding-top: 90px;
              }
            `}
          >
            <BrandedCard
              isBranded
              hasMininumHeight
              cardMediaBackground
              // data={BRANDED_CARD_DATA.cards}
              data={
                this.state.selectedItem
                  ? this.props.data.filter(
                      item =>
                        item.category.indexOf(this.state.selectedItem) > -1
                    )
                  : this.props.data
              }
            />
          </div>
        )}
      </div>
    );
  }
}

export default FilterList;
